import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import RubricOrTagTopics from 'site/components/RubricOrTagTopics';

import { denormalizeData } from 'core/utils/api';

/**
 * Бесконечный скролл в рубрике.
 * Сначала подгружаем топики из текущей рубрики.
 * Когда рубрика истощена, загружаем остальные топики.
 */
class InfinityTopics extends React.PureComponent {
  static contextTypes = {
    bebopApi: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.anchor = React.createRef();

    this.state = {
      loading: false,
      contentGroups: [props.topics],
      filterByRubric: true,
      groupSizeByRubric: 0,
      filteredByRubricTopicsSize: 0,
    };
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(entries => {
      const { limit, match } = this.props;
      const { bebopApi } = this.context;
      const { level_1: level1 } = match.params;
      const {
        contentGroups,
        loading,
        filterByRubric,
        groupSizeByRubric,
        filteredByRubricTopicsSize,
      } = this.state;
      const groupsSize = contentGroups.length;

      if (loading) return;

      if (entries.some(entry => entry.isIntersecting)) {
        this.setState({ loading: true });

        let offset;
        if (filterByRubric) {
          offset = groupsSize * limit;
        } else {
          offset = (groupsSize - groupSizeByRubric) * (limit - filteredByRubricTopicsSize);
          this.setState({ filteredByRubricTopicsSize: 0 });
        }

        bebopApi.getTopics({
          limit,
          include: 'image',
          offset: Boolean(offset) ? offset : '',
          ...(filterByRubric
            ? { rubric_root: level1, excluded_rubrics: 'vse-materialy' }
            : { excluded_rubrics: `${level1},vse-materialy` }
          ),
        })
          .then(denormalizeData)
          .then(group => {
          /**
           * Топики отфильтрованные по рубрике закончились.
           * Подгружаем остальные топики, без фильтрации по рубрике.
           */
            return new Promise(resolve => {
              if (filterByRubric && group.length < limit) {
                bebopApi.getTopics({
                  excluded_rubrics: `${level1},vse-materialy`,
                  limit: limit - group.length,
                  include: 'image',
                })
                  .then(denormalizeData)
                  .then(additionalGroup => {
                    this.setState({
                      filterByRubric: false,
                      groupSizeByRubric: groupsSize,
                      filteredByRubricTopicsSize: group.length,
                    });
                    resolve([...group, ...additionalGroup]);
                  });
              } else {
                resolve(group);
              }
            });
          })
          .then(group => {
            this.setState(prevState => ({
              contentGroups: prevState.contentGroups.concat([group]),
              loading: false,
            }));
          })
          .catch(error => console.error(error));
      }
    }, {
      // Начинаем подгрузку следующего топика за 1 экран
      rootMargin: '100% 0%',
    });

    this.observer.observe(this.anchor.current);
  }

  render() {
    const { contentGroups } = this.state;

    return (
      <>
        {contentGroups.map((group, index) => {
          return (
            <RubricOrTagTopics
              key={index}
              topics={group}
            />
          );
        })}
        <div ref={this.anchor} />
      </>
    );
  }
}

InfinityTopics.propTypes = {
  topics: PropTypes.array,
  limit: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(InfinityTopics);
