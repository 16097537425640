import PropTypes from 'prop-types';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import { INDENT } from 'site/constants';

import { Indent } from 'site/components/Wrappers';
import MasonryFeed from 'site/components/MasonryFeed';

import { Card1Type1 } from 'site/cards/Card1';
import { Card2Type1 } from 'site/cards/Card2';

import { Ad280x430 } from 'site/components/Ad/desktop';
import { Ad320x492, Billboard as BillboardMobile } from 'site/components/Ad/mobile';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';


const firstBlockCards = [
  { component: Card1Type1, margin: false },
  { component: Card2Type1, margin: false },
  { component: Card1Type1, margin: false },
  { component: Card2Type1, margin: false },
  { component: Card2Type1, margin: true },
  { component: Card1Type1, margin: false },
  { component: Card2Type1, margin: true },
  { component: Card1Type1, margin: false },
];

const secondBlockCards = [
  { component: Card2Type1, margin: false },
  { component: Card1Type1, margin: false },
  { component: Card2Type1, margin: false },
  { component: Card1Type1, margin: false },
  { component: Card1Type1, margin: false },
  { component: Card2Type1, margin: true },
  { component: Card1Type1, margin: false },
  { component: Card2Type1, margin: true },
];

const RubricPage = ({ topics, isMobile, showAds }) => {
  const firstBlock = topics.slice(0, 8);
  const secondBlock = isMobile ? [isMobile && firstBlock.pop()] : topics.slice(8, 16);

  return (
    <>
      {!!firstBlock.length && (
        <MasonryFeed
          content={firstBlock}
          columns={4}
          interitemSpacing={20}
          cards={firstBlockCards}
          cardsReplacement={{
            1: replaceCardWithAd(isMobile ? Ad320x492 : Ad280x430),
          }}
          cardsMobileAdditional={{
            4: BillboardMobile,
          }}
        />
      )}
      <Indent top={INDENT} />
      {showAds && (
        <Desktop>
          <Billboard />
        </Desktop>
      )}
      <Indent top={INDENT} />
      {!!secondBlock.length && (
        <MasonryFeed
          content={secondBlock}
          columns={4}
          interitemSpacing={20}
          cards={secondBlockCards}
          cardsMobileAdditional={{
            4: BillboardMobile,
          }}
        />
      )}
      {showAds && (
        <Indent top={INDENT} bottom={INDENT}>
          <Billboard />
        </Indent>
      )}
    </>
  );
};

RubricPage.defaultProps = {
  showAds: true,
};

RubricPage.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  topics: PropTypes.array,
  showAds: PropTypes.bool,
};

export default withBreakpoint(RubricPage);
