import { Mobile, Desktop } from 'core/components/breakpoint';

import {
  Billboard as BillboardMobile,
  StickyLine as StickyLineMobile,
  Fullscreen as FullscreenMobile,
  Slider as SliderMobile,
  BillboardSpec as BillboardSpecMobile,
  Footer as FooterMobile,
} from './mobile';

import {
  Billboard as BillboardDesktop,
  StickyLine as StickyLineDesktop,
  Fullscreen as FullscreenDesktop,
  Slider as SliderDesktop,
  BillboardSpec as BillboardSpecDesktop,
  Footer as FooterDesktop,
} from './desktop';

import styles from './index.styl';

export function Billboard(props) {
  return (
    <>
      <Mobile>
        <BillboardMobile {...props} />
      </Mobile>
      <Desktop>
        <BillboardDesktop {...props} />
      </Desktop>
    </>
  );
}

export function BillboardSpec(props) {
  return (
    <>
      <Mobile>
        <BillboardSpecMobile {...props} />
      </Mobile>
      <Desktop>
        <BillboardSpecDesktop {...props} />
      </Desktop>
    </>
  );
}

export function StickyLine(props) {
  return (
    <>
      <Mobile>
        <StickyLineMobile {...props} />
      </Mobile>
      <Desktop>
        <StickyLineDesktop {...props} />
      </Desktop>
    </>
  );
}

export function Fullscreen(props) {
  return (
    <>
      <Mobile>
        <FullscreenMobile {...props} />
      </Mobile>
      <Desktop>
        <FullscreenDesktop {...props} />
      </Desktop>
    </>
  );
}

export function Slider(props) {
  return (
    <>
      <Mobile>
        <SliderMobile {...props} />
      </Mobile>
      <Desktop>
        <SliderDesktop {...props} />
      </Desktop>
    </>
  );
}

export function Footer(props) {
  return (
    <div className={styles.footer}>
      <Mobile>
        <FooterMobile {...props} />
      </Mobile>
      <Desktop>
        <FooterDesktop {...props} />
      </Desktop>
    </div>
  );
}
