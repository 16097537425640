import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, {
  readMoreAttributes,
} from 'core/utils/prop-types/model';
import { denormalizeData } from 'core/utils/api';

import { READ_MORE_CLASS } from 'site/constants';

import Drum from 'core/components/Drum';
import { SimpleLoader } from 'core/components/Loader';
import Feed from 'core/components/Feed';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import { Description } from 'site/components/Texts';

import { Card1Type2 } from 'site/cards/Card1';

import styles from './index.styl';


export default function ReadMore({ block, parentProps }, { bebopApi }) {
  const {
    isMobile,
    isDesktop,
    theme,
  } = parentProps;

  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  const ids = block?.attributes?.topic_ids;

  useEffect(() => {
    let ignore = false;

    const exitEffect = () => {
      ignore = true;
    };

    if (!ids.length) return exitEffect;

    bebopApi
      .getTopics({
        include: 'image,rubric',
        ids: ids.join(','),
        ...isDesktop && { limit: 6 },
      })
      .then(data => {
        if (!ignore) {
          setContent(denormalizeData(data));
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });
    return exitEffect;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  if (loading) return <SimpleLoader />;

  if (!content?.length) return null;

  return (
    <div className={styles.readMore}>
      <style jsx>{`
        .${styles.readMore}
          border-color ${theme.colors.grey1}
      `}</style>
      <Description tag='span' className={styles.title}>Читайте также:</Description>
      <MaxWidthWrapper className={READ_MORE_CLASS}>
        {isMobile ? (
          <Drum
            content={content}
            card={(cardsProps) => {
              return <Card1Type2 {...cardsProps} />;
            }}
            noCounter
            itemWidthMobile='220px'
          />
        ) : (
          <Feed
            content={content}
            interitemSpacing={20}
            columns={3}
            card={Card1Type2}
          />
        )}
      </MaxWidthWrapper>
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};

ReadMore.contextTypes = {
  bebopApi: PropTypes.object,
};
